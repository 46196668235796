import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import './FreeVoucherBuy.scss';
import classNames from 'classnames';
import axios from 'axios';
import errorIcon from '../../ReservationWizardAutoComplete/error-icon.svg';
import Loader from '../../Loader/Loader';
import GetApiHeaders from '../../../utils/GetApiHeaders';


const PaymentMethod = ({
  // eslint-disable-next-line react/prop-types
  method, handleClick, selected, issuers, handleSelectIssuer,
}) => (
  <div
    className={classNames('buy__paymentMethod flex-fill w-100',
      { 'buy__paymentMethod--selected': selected })}
    onClick={handleClick}
  >

    <img className="buy__paymentMethod__img" src={method.svg} alt="logo" />
    <span className="buy__paymentMethod__title">{method.name}</span>
  </div>
);


const FreeVoucherBuy = () => {
  const getName = () => ('Cedric');
  const [data, setData] = useState();
  const [paymentLoading, setPaymentLoading] = useState();
  const [selectedMethod, setSelectedMethod] = useState();
  const [selectedIssuer, setSelectedIssuer] = useState();
  const [voucherQuantity, setVoucherQuantity] = useState(1);

  const fetchData = async () => {
    try {
      const request = await axios.get(`${process.env.CONF_API_URL}/api/freevoucher/buy`, { ...GetApiHeaders() });
      if (request.status === 200) {
        setData(request.data);
      }
    } catch (e) {
      if (e.status === 401) { window.location = '/auth/login?redirect=/vouchers/buy'; }
    }
  };

  const onSelectIssuer = (issuer) => {
    setSelectedMethod('ideal');
    setSelectedIssuer(issuer);
  };

  const payOrder = async () => {
    setPaymentLoading(true);
    const body = {
      method: selectedMethod,
      issuer: selectedIssuer,
      quantity: +voucherQuantity,
      returnUrl: `${process.env.CONF_FRONTEND_HOST}/subscriptions?ordered=true`,
    };

    const paymentRequest = await axios.post(
      `${process.env.CONF_API_URL}/api/freevoucher/pay`,
      body,
      { ...GetApiHeaders() },
    );

    if (paymentRequest.status === 200) {
      const { paymentUrl } = paymentRequest.data;
      if (paymentUrl) {
        window.location = paymentUrl;
      }
    }
  };


  useEffect(() => { fetchData(); }, []);


  return (
    <section className="wizardStep wizardStep--withMenu">
      <Grid className="wizardStep__section">
        <div className="wizardStep__section__wrapper">
          <h1>Koop vrije voucher</h1>

          {data && data.allowPurchase && (
          <p>
                Wil je een keer extra golfen, koop dan een vrije voucher,
                deze is vrij te gebruiken op een lopend abonnement.
            <br />
            <br />
            <strong>Prijs per voucher:</strong>
            {' '}
            <span dangerouslySetInnerHTML={{ __html: data.formattedPrice }} />
            <br />
            <strong>Holes: </strong>
            <span>{data.holes}</span>

            <br />
            <strong>Aantal vouchers: </strong>

            <select className="buy__paymentMethod__issuers" onChange={e => setVoucherQuantity(e.currentTarget.value)}>
              {[...Array(data.maxBuyable)].map((c, i) => (
                <option value={i + 1}>
                  {i + 1}
                  {' ('}
                  {new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  }).format((i + 1) * data.price)}
)
                </option>
              ))
                  }
            </select>

            <br />

          </p>
          )}
        </div>


        {!data && <Loader size="48" />}

        {data && data.methods && (

            <>
              <h2 className="mt-5 mb-2">Kies een betaalmethode:</h2>

              <div className="d-md-flex">
                {data.methods.map(method => (
                  <PaymentMethod
                    method={method}
                    selected={selectedMethod === method.id}
                    handleClick={() => {
                      setSelectedMethod(method.id);
                    }}
                    handleSelectIssuer={onSelectIssuer}
                    issuers={data.issuers}
                  />))}
              </div>


              <div className="mt-5 w-100 text-right">
                <button
                  type="button"
                  disabled={!selectedMethod || paymentLoading}
                  className="reservationWizard__buttons__button reservationWizard__buttons__button__next"
                  onClick={payOrder}
                >
verder naar betalen
                </button>
              </div>

            </>)
        }

        {data && (data.error || data.allowPurchase === false) && (
        <Col xs={12} sm={10} componentClass="mb-2">
          <div className={classNames('wizard-autocomplete-warning no-arrow', { active: true })}>
            <img src={errorIcon} alt="" />
            <span className="wizard-autocomplete-warning-message">
              {data.message || 'Er is een onbekende fout opgetreden'}
            </span>
          </div>
        </Col>
        )}
      </Grid>
    </section>
  );
};

export default FreeVoucherBuy;
